<template>
  <b-container class="dashboard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col cols="12" md="12" lg="8">
        <h1 v-if="currentUser">
          {{ $t("dashboard.welcome_user", { user: currentUser.firstname }) }}
        </h1>
        <p class="lead">
          {{ $t("dashboard.intro_lead") }}
        </p>
        <i18n :path="'dashboard.intro_p1'" tag="p" class="text-gray">
          <b-link slot="get-started-guide" href="https://forum.spirecta.se/t/kom-igang-ratt-och-latt-med-spirecta-borja-har/14" target="_blank" class="text-red">{{ $t("dashboard.intro_get_started_guide") }}</b-link>
          <b-link slot="spirecta-communityn" href="https://forum.spirecta.se/t/valkommen-till-spirecta-forumet-borja-har/7" target="_blank" class="text-red">{{ $t("dashboard.intro_community") }}</b-link>
        </i18n>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-4 mb-xl-5">
        <a :href="openGettingStartedGuide" target="_blank">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/getting-started-guide.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.get_started_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.get_started_description") }}
            </b-card-text>
            <div slot="footer" v-if="!bSubscriptionExpired">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" :href="openGettingStartedGuide" variant="primary" target="_blank">
                {{ $t("dashboard.get_started_button") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </a>
      </b-col>

      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-4 mb-xl-5">
        <b-link :to="!bSubscriptionExpired ? '/transactions/import' : '#'">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/import.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.transactions_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.transactions_description") }}
            </b-card-text>
            <div slot="footer" v-if="!bSubscriptionExpired">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" to="/transactions/import" variant="primary">
                {{ $t("dashboard.transactions_button") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </b-link>
      </b-col>

      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-4 mb-xl-5">
        <b-link :to="!bSubscriptionExpired ? '/reports/performance' : '#'">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/reports-insights.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.reports_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.reports_description") }}
            </b-card-text>
            <div slot="footer" v-if="!bSubscriptionExpired">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" to="/reports/performance" variant="primary">
                {{ $t("dashboard.reports_button") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </b-link>
      </b-col>

      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-4 mb-xl-5">
        <b-link :to="
            !bSubscriptionExpired ? '/reports/other/account-plan-v2/incomes' : '#'
          ">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/account-plan.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.manage_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.manage_description") }}
            </b-card-text>
            <div slot="footer" v-if="!bSubscriptionExpired">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" to="/reports/other/account-plan-v2/incomes" variant="primary">
                {{ $t("dashboard.manage_button") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>

    <b-row class="main-content-wrapper mt-0 pt-0" no-gutters>
      <b-col>
        <h1>{{ $t(translationPath + "section_manual_title") }}</h1>
        <p class="col-lg-8 px-0">
          {{ $t(translationPath + "section_manual_description") }}
        </p>
      </b-col>
    </b-row>

    <b-row>

      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-3 mb-xl-5">
        <a href="https://forum.spirecta.se/t/1288" target="_blank">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/firedrill.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.events.2022.firedrill_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.events.2022.firedrill_description") }}
            </b-card-text>
            <div slot="footer" v-if="!bSubscriptionExpired">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" href="https://forum.spirecta.se/t/1288" target="_blank" variant="primary">
                {{ $t("dashboard.events.2022.firedrill_open") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </a>
      </b-col>

      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-3 mb-xl-5">
        <a href="https://forum.spirecta.se/t/fastnat-boka-ett-supportmote/964" target="_blank">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/support-meeting.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.support_meeting_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.support_meeting_description") }}
            </b-card-text>
            <div slot="footer">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" href="https://forum.spirecta.se/t/fastnat-boka-ett-supportmote/964" target="_blank" variant="primary">
                {{ $t("dashboard.support_meeting_button") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </a>
      </b-col>

      <b-col lg="3" md="6" sm="12" class="mb-2 mb-md-3 mb-lg-3 mb-xl-5">
        <a :href="'https://forum.spirecta.se/t/valkommen-till-spirecta-forumet-borja-har/7'" target="_blank">
          <b-card class="reports_cards_main mb-2" :img-src="require('@/assets/img/manual-indepth.png')" img-alt="Image">
            <b-card-title class="text-left">
              {{ $t("dashboard.manual_title") }}
            </b-card-title>
            <b-card-text>
              {{ $t("dashboard.manual_description") }}
            </b-card-text>
            <div slot="footer">
              <b-button class="btn btn-sm btn-primary font-weight-bold d-block" href="https://forum.spirecta.se/t/valkommen-till-spirecta-forumet-borja-har/7" target="_blank" variant="primary">
                {{ $t("dashboard.manual_button") }}
                <i class="flaticon stroke right-2"></i>
              </b-button>
            </div>
          </b-card>
        </a>
      </b-col>
    </b-row>

    <!-- MODAL SUBSCRIPTION EXPIRED -->
    <b-modal id="bSubscriptionExpiredired" ref="SubscriptionExpiredModal" centered>
      <template #modal-title>
        {{ $t(translationPath + 'expired_modal.title')}}
      </template>
      <div class="d-block">
        <p>{{ $t(translationPath + 'expired_modal.description')}}</p>
      </div>
      <template #modal-footer>
        <b-button variant="outline" @click="$bvModal.hide('bSubscriptionExpiredired')">
          {{ $t('common.cancel')}}
        </b-button>
        <b-button variant="primary" @click="$router.push('/settings/subscription/details')">
          {{ $t(translationPath + 'expired_modal.button')}}
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import titleMixins from '@/mixins/title'
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  mixins: [titleMixins],
  props: ['currentUser', 'currentCoa'],
  data () {
    return {
      checked: false,
      translationPath: 'dashboard.'
    }
  },
  mounted () {
    if (this.subscriptionExp) {
      this.$refs.SubscriptionExpiredModal.show()
    }
  },
  computed: {
    ...mapState('user', ['subscriptionExp']),
    bSubscriptionExpired () {
      return !this.currentUser.subscription_status_subscribed
    },
    titleMeta () {
      return this.$t('common.header.header_menu.dashboard')
    },
    openGettingStartedGuide () {
      switch (this.currentUser.default_lang) {
        case 'sv':
          return 'https://forum.spirecta.se/t/kom-igang-ratt-och-latt-med-spirecta-borja-har/14'
        case 'da':
          return 'https://forum.spirecta.se/t/kom-igang-ratt-och-latt-med-spirecta-borja-har/14'
        case 'en':
        default:
          return 'https://forum.spirecta.se/t/kom-igang-ratt-och-latt-med-spirecta-borja-har/14'
      }
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    subscriptionExp () {
      if (this.subscriptionExp) {
        this.$refs.SubscriptionExpiredModal.show()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/reports-cards.scss";
.dashboard {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #545b7a;
    &.lead {
      font-size: 17px;
      font-weight: 400;
      line-height: 29px;
      padding: 0;
      margin-bottom: 10px;
    }
  }
  li {
    font-size: 14px;
    padding-bottom: 10px;
    line-height: 25px;
  }

  .card-icon {
    font-size: 48px;
    font-weight: 200;
  }

  // .card-img{
  // background: #f7f7f7;
  // border-bottom: 1px solid #d8d8d8;
  // }
  .btn {
    border-radius: 20px;
    padding: 5px 12px;
    margin-right: 10px;
    font-size: 80%;
  }
  .btn i {
    vertical-align: inherit;
  }
  .card-body {
    min-height: 145px;
    text-align: left;
    @media screen and (min-width: 992px) and (max-width: 1439px) {
      min-height: 200px;
    }
  }
}
</style>
